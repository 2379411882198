.icon-add-filled:before {
    content: '\f101';
}
.icon-add-outline:before {
    content: '\f102';
}
.icon-alert-active:before,
.icon-alert-filled:before {
    content: '\f103';
}
.icon-alert-outline:before {
    content: '\f104';
}
.icon-arrow-down-active-small:before,
.icon-arrow-down-filled-small:before {
    content: '\f105';
}
.icon-arrow-down-filled:before {
    content: '\f106';
}
.icon-arrow-down-outline-small:before {
    content: '\f107';
}
.icon-arrow-down-outline:before {
    content: '\f108';
}
.icon-arrow-left-filled-small:before {
    content: '\f109';
}
.icon-arrow-left-filled:before {
    content: '\f10a';
}
.icon-arrow-left-outline-small:before {
    content: '\f10b';
}
.icon-arrow-left-outline:before {
    content: '\f10c';
}
.icon-arrow-right-active-small:before,
.icon-arrow-right-filled-small:before {
    content: '\f10d';
}
.icon-arrow-right-filled:before {
    content: '\f10e';
}
.icon-arrow-right-outline-small:before {
    content: '\f10f';
}
.icon-arrow-right-inactive:before,
.icon-arrow-right-outline:before {
    content: '\f110';
}
.icon-arrow-up-filled-small:before {
    content: '\f111';
}
.icon-arrow-up-filled:before {
    content: '\f112';
}
.icon-arrow-up-outline-small:before {
    content: '\f113';
}
.icon-arrow-up-outline:before {
    content: '\f114';
}
.icon-attachment-filled:before {
    content: '\f115';
}
.icon-attachment-outline:before {
    content: '\f116';
}
.icon-barrier-free-filled:before {
    content: '\f117';
}
.icon-barrier-free-outline:before {
    content: '\f118';
}
.icon-breadcrumb-filled:before {
    content: '\f119';
}
.icon-breadcrumb-outline:before {
    content: '\f11a';
}
.icon-break-filled:before {
    content: '\f11b';
}
.icon-break-outline:before {
    content: '\f11c';
}
.icon-burger:before {
    content: '\f11d';
}
.icon-calendar-filled:before {
    content: '\f11e';
}
.icon-calendar-inactive:before,
.icon-calendar-outline:before {
    content: '\f11f';
}
.icon-calendar-today-filled:before {
    content: '\f120';
}
.icon-calendar-today-outline:before {
    content: '\f121';
}
.icon-camera-filled:before {
    content: '\f122';
}
.icon-camera-outline:before {
    content: '\f123';
}
.icon-category-filled:before {
    content: '\f124';
}
.icon-category-outline:before {
    content: '\f125';
}
.icon-checkbox-filled:before {
    content: '\f126';
}
.icon-checkbox-outline:before {
    content: '\f127';
}
.icon-checkbox-preselect:before {
    content: '\f128';
}
.icon-clock-filled:before {
    content: '\f129';
}
.icon-clock-outline:before {
    content: '\f12a';
}
.icon-close-filled-small:before {
    content: '\f12b';
}
.icon-close-outline-small:before {
    content: '\f12c';
}
.icon-close:before {
    content: '\f12d';
}
.icon-dashboard-filled:before {
    content: '\f12e';
}
.icon-dashboard-outline:before {
    content: '\f12f';
}
.icon-delete-active:before,
.icon-delete-filled:before {
    content: '\f130';
}
.icon-delete-outline:before {
    content: '\f131';
}
.icon-delivery-filled:before {
    content: '\f132';
}
.icon-delivery-outline:before {
    content: '\f133';
}
.icon-do-filled:before {
    content: '\f134';
}
.icon-do-outline:before {
    content: '\f135';
}
.icon-document-filled:before {
    content: '\f136';
}
.icon-document-outline-outline:before {
    content: '\f137';
}
.icon-drag-edge:before {
    content: '\f138';
}
.icon-drag-point:before {
    content: '\f139';
}
.icon-duplicate-filled:before {
    content: '\f13a';
}
.icon-duplicate-outline:before {
    content: '\f13b';
}
.icon-e1-logo:before {
    content: '\f13c';
}
.icon-edit-inactive:before,
.icon-edit-filled:before {
    content: '\f13d';
}
.icon-edit-outline:before {
    content: '\f13e';
}
.icon-export-filled:before {
    content: '\f13f';
}
.icon-export-outline:before {
    content: '\f140';
}
.icon-favourite-filled:before {
    content: '\f141';
}
.icon-favourite-outline:before {
    content: '\f142';
}
.icon-filter-filled:before {
    content: '\f143';
}
.icon-filter-outline:before {
    content: '\f144';
}
.icon-fit-to-mobile-filled:before {
    content: '\f145';
}
.icon-fit-to-mobile-outline:before {
    content: '\f146';
}
.icon-folder-filled:before {
    content: '\f147';
}
.icon-folder-open-filled:before {
    content: '\f148';
}
.icon-folder-open-outline:before {
    content: '\f149';
}
.icon-folder-outline:before {
    content: '\f14a';
}
.icon-fullscreen-filled:before {
    content: '\f14b';
}
.icon-fullscreen-outline:before {
    content: '\f14c';
}
.icon-graphicview-filled:before {
    content: '\f14d';
}
.icon-graphicview-outline:before {
    content: '\f14e';
}
.icon-grouping-filled:before {
    content: '\f14f';
}
.icon-grouping-outline:before {
    content: '\f150';
}
.icon-handover-filled:before {
    content: '\f151';
}
.icon-handover-outline:before {
    content: '\f152';
}
.icon-hourglass-filled:before {
    content: '\f153';
}
.icon-hourglass-outline:before {
    content: '\f154';
}
.icon-human-active:before,
.icon-human-filled:before {
    content: '\f155';
}
.icon-human-inactive:before,
.icon-human-outline:before {
    content: '\f156';
}
.icon-import-filled:before {
    content: '\f157';
}
.icon-import-outline:before {
    content: '\f158';
}
.icon-in-progress-filled:before {
    content: '\f159';
}
.icon-in-progress-outline:before {
    content: '\f15a';
}
.icon-incident-filled:before {
    content: '\f15b';
}
.icon-incident-outline:before {
    content: '\f15c';
}
.icon-info-inactive:before,
.icon-info-filled:before {
    content: '\f15d';
}
.icon-info-outline:before {
    content: '\f15e';
}
.icon-like-filled:before {
    content: '\f15f';
}
.icon-like-outline:before {
    content: '\f160';
}
.icon-link-active:before,
.icon-link-filled:before {
    content: '\f161';
}
.icon-link-inactive:before,
.icon-link-outline:before {
    content: '\f162';
}
.icon-listview-filled:before {
    content: '\f163';
}
.icon-listview-inactive:before,
.icon-listview-outline:before {
    content: '\f164';
}
.icon-lock-filled-filled:before {
    content: '\f165';
}
.icon-lock-active:before,
.icon-lock-filled:before {
    content: '\f166';
}
.icon-lock-outline-filled:before {
    content: '\f167';
}
.icon-lock-inactive:before,
.icon-lock-outline:before {
    content: '\f168';
}
.icon-machine-filled:before {
    content: '\f169';
}
.icon-machine-outline:before {
    content: '\f16a';
}
.icon-more-active:before,
.icon-more-filled:before {
    content: '\f16b';
}
.icon-more-inactive:before,
.icon-more-outline:before {
    content: '\f16c';
}
.icon-note-filled:before {
    content: '\f16d';
}
.icon-note-outline:before {
    content: '\f16e';
}
.icon-notification-active:before,
.icon-notification-filled:before {
    content: '\f16f';
}
.icon-notification-inactive:before,
.icon-notification-outline:before {
    content: '\f170';
}
.icon-notok-filled:before {
    content: '\f171';
}
.icon-notok-outline:before {
    content: '\f172';
}
.icon-parametarize-filled:before {
    content: '\f173';
}
.icon-parametarize-outline:before {
    content: '\f174';
}
.icon-pin-filled:before {
    content: '\f175';
}
.icon-pin-outline:before {
    content: '\f176';
}
.icon-plant-filled:before {
    content: '\f177';
}
.icon-plant-outline:before {
    content: '\f178';
}
.icon-play-filled:before {
    content: '\f179';
}
.icon-play-inactive:before,
.icon-play-outline:before {
    content: '\f17a';
}
.icon-print-filled:before {
    content: '\f17b';
}
.icon-print-outline:before {
    content: '\f17c';
}
.icon-prio-high-filled:before {
    content: '\f17d';
}
.icon-prio-high-outline:before {
    content: '\f17e';
}
.icon-prio-low-filled:before {
    content: '\f17f';
}
.icon-prio-low-outline:before {
    content: '\f180';
}
.icon-prio-middle-filled:before {
    content: '\f181';
}
.icon-prio-middle-outline:before {
    content: '\f182';
}
.icon-product-inactive:before,
.icon-product-filled:before {
    content: '\f183';
}
.icon-product-outline:before {
    content: '\f184';
}
.icon-quality-filled:before {
    content: '\f185';
}
.icon-quality-outline:before {
    content: '\f186';
}
.icon-radio-button-filled:before {
    content: '\f187';
}
.icon-radio-button-outline:before {
    content: '\f188';
}
.icon-reset-filled:before {
    content: '\f189';
}
.icon-reset-inactive:before,
.icon-reset-outline:before {
    content: '\f18a';
}
.icon-save-filled:before {
    content: '\f18b';
}
.icon-save-outline:before {
    content: '\f18c';
}
.icon-scrollable-filled:before {
    content: '\f18d';
}
.icon-scrollable-outline:before {
    content: '\f18e';
}
.icon-search:before {
    content: '\f18f';
}
.icon-setting-filled:before {
    content: '\f190';
}
.icon-setting-outline:before {
    content: '\f191';
}
.icon-shiftbook-filled:before {
    content: '\f192';
}
.icon-shiftbook-outline:before {
    content: '\f193';
}
.icon-sort-ascending:before {
    content: '\f194';
}
.icon-sort-descending:before {
    content: '\f195';
}
.icon-sort-filled:before {
    content: '\f196';
}
.icon-sort-outline:before {
    content: '\f197';
}
.icon-start-inspection-filled:before {
    content: '\f198';
}
.icon-start-inspection-outline:before {
    content: '\f199';
}
.icon-status-filled:before {
    content: '\f19a';
}
.icon-status-outline:before {
    content: '\f19b';
}
.icon-stop-filled:before {
    content: '\f19c';
}
.icon-stop-outline:before {
    content: '\f19d';
}
.icon-success-active:before,
.icon-success-filled:before {
    content: '\f19e';
}
.icon-success-inactive:before,
.icon-success-outline:before {
    content: '\f19f';
}
.icon-table-column-choose:before {
    content: '\f1a0';
}
.icon-tableview-filled:before {
    content: '\f1a1';
}
.icon-tableview-outline:before {
    content: '\f1a2';
}
.icon-tag-filled:before {
    content: '\f1a3';
}
.icon-tag-outline:before {
    content: '\f1a4';
}
.icon-task-filled:before {
    content: '\f1a5';
}
.icon-task-outline:before {
    content: '\f1a6';
}
.icon-toggle-filled:before {
    content: '\f1a7';
}
.icon-toggle-outline:before {
    content: '\f1a8';
}
.icon-transfer-from-filled:before {
    content: '\f1a9';
}
.icon-transfer-from-outline:before {
    content: '\f1aa';
}
.icon-transfer-to-filled:before {
    content: '\f1ab';
}
.icon-transfer-to-outline:before {
    content: '\f1ac';
}
.icon-tree-checkbox-filled:before {
    content: '\f1ad';
}
.icon-tree-checkbox-outline:before {
    content: '\f1ae';
}
.icon-tree-checkbox-preselect:before {
    content: '\f1af';
}
.icon-trending-negative-filled:before {
    content: '\f1b0';
}
.icon-trending-negative-outline:before {
    content: '\f1b1';
}
.icon-trending-positive-filled:before {
    content: '\f1b2';
}
.icon-trending-positive-outline:before {
    content: '\f1b3';
}
.icon-undo-filled:before {
    content: '\f1b4';
}
.icon-undo-outline:before {
    content: '\f1b5';
}
.icon-vector-filled:before {
    content: '\f1b6';
}
.icon-visible-filled:before {
    content: '\f1b7';
}
.icon-visible-outline:before {
    content: '\f1b8';
}
.icon-warning-filled:before {
    content: '\f1b9';
}
.icon-warning-inactive:before,
.icon-warning-outline:before {
    content: '\f1ba';
}
.icon-workstation-filled:before {
    content: '\f1bb';
}
.icon-workstation-outline:before {
    content: '\f1bc';
}
.icon-zoom-in-filled:before {
    content: '\f1bd';
}
.icon-zoom-in-outline:before {
    content: '\f1be';
}
.icon-zoom-out-filled:before {
    content: '\f1bf';
}
.icon-zoom-out-outline:before {
    content: '\f1c0';
}

// EdgeOne Fonts
@font-face{font-family:GermanedgeSans-Regular; src: url('/assets/fonts/GermanedgeSans-Regular.otf'); }
@font-face{font-family:GermanedgeSans; src:url('/assets/fonts/GermanedgeSans-Regular.woff2') format("woff2"),url('/assets/fonts/GermanedgeSans-Regular.otf') format("opentype"); font-weight:400; }
@font-face{font-family:GermanedgeSans-SemiBold; src:url('/assets/fonts/GermanedgeSans-SemiBold.ttf') format("truetype"),url('/assets/fonts/GermanedgeSans-SemiBold.woff') format("woff"),url('/assets/fonts/GermanedgeSans-SemiBold.woff2') format("woff2"); font-weight:400; text-rendering:optimizeLegibility }
@font-face{font-family:GermanedgeSans-Bold; src:url('/assets/fonts/GermanedgeSans-Bold.woff2') format("woff2"),url('/assets/fonts/GermanedgeSans-Bold.otf') format("opentype"); font-weight:400; text-rendering:optimizeLegibility }
@font-face{font-family:GermanedgeSans-Cn;src:url('/assets/fonts/GermanedgeSans-Cn-Regular.woff2') format("woff2"),url('/assets/fonts/GermanedgeSans-Cn-Regular.otf') format("opentype"); font-weight:400}
@font-face{font-family:GermanedgeSansCn;src:url('/assets/fonts/GermanedgeSans-Cn-Regular.woff2') format("woff2"),url('/assets/fonts/GermanedgeSans-Cn-Regular.otf') format("opentype"); font-weight:400}
@font-face{font-family:GermanedgeSans-Cn-SemiBold;src:url('/assets/fonts/GermanedgeSans-Cn-SemiBold.woff2') format("woff2"),url('/assets/fonts/GermanedgeSans-Cn-SemiBold.otf') format("opentype"); font-weight:400}
@font-face{font-family:GermanedgeSans-Cn-Bold;src:url('/assets/fonts/GermanedgeSans-Cn-Bold.woff2') format("woff2"),url('/assets/fonts/GermanedgeSans-Cn-Bold.otf') format("opentype"); font-weight:400}
@font-face{font-family:e1-icons;src:url('/assets/fonts/e1-icons.eot?42fa859ac8fd6e36f9e96fb26d493f91?#iefix') format('embedded-opentype'),url('/assets/fonts/e1-icons.woff2?42fa859ac8fd6e36f9e96fb26d493f91') format('woff2'), url('/assets/fonts/e1-icons.woff?42fa859ac8fd6e36f9e96fb26d493f91') format('woff'), url('/assets/fonts/e1-icons.ttf?42fa859ac8fd6e36f9e96fb26d493f91') format('truetype'), url('/assets/fonts/e1-icons.svg?42fa859ac8fd6e36f9e96fb26d493f91#e1-icons') format('svg'); }

// Variables
@import 'variables';

// Bootstrap
@import '~admin-lte/build/scss/adminlte';

// Plugins
@import "~admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.css";
@import "~admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.css";
@import "~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.css";
@import "~admin-lte/plugins/select2/css/select2.min.css";
@import "~admin-lte/plugins/summernote/summernote-bs4.min.css";
@import "~admin-lte/plugins/flag-icon-css/css/flag-icon.min.css";
@import "admin-lte/plugins/chart.js/Chart.min.css";
@import "admin-lte/plugins/toastr/toastr.min.css";
@import "admin-lte/plugins/ekko-lightbox/ekko-lightbox.css";

// Floating labels
@import "../css/floating-labels";

// EdgeOne Icons
@import "../css/e1-icons";

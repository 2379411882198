select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii00NzMgMjc3IDEyIDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTQ3MyAyNzcgMTIgODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6IzhBOTNBNjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTS00NzEuNiwyNzcuM2w0LjYsNC42bDQuNi00LjZsMS40LDEuNGwtNiw2bC02LTZMLTQ3MS42LDI3Ny4zeiIvPg0KPC9zdmc+DQo=) calc(100% - 18px) / 11px no-repeat;
}

.form-group{
    position: relative;
    margin-bottom: 10px;
}
.form-control {
    font-family: GermanedgeSans-Cn-SemiBold,Arial,Helvetica,sans-serif;
    display: block;
    width: 100%;
    height: auto;
    padding: 8px 14px;
    padding-top: 21px;
    min-height: 56px;
    font-size: 14px;
    color: #3f3e3d;
    background-color: #f2f2f2;
    border: 1px solid #e7e6e6;
    border-radius: 0;
    -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
select.form-control{
    padding-top: 10px;
    transition: 0.15s;
}
.form-control:focus {
    color: #3f3e3d;
    background-color: #FFF;
    border-color: #5A8DEE;
    outline: 0;
    box-shadow: none;
}
.floating-label {
    font-family: GermanedgeSansCn,Arial,Helvetica,sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #3f3e3d;
    opacity: 1;
    top: 20px;
    left: 20px;
    pointer-events: none;
    position: absolute;
    transition: 240ms;
    margin-bottom: 0;
    z-index: 1;
}
.floating-diff .floating-label{
    opacity: 0;
}
.floating-diff.focused .floating-label{
    opacity: 1;
}
.form-group.focused .floating-label {
    opacity: 1;
    color: #7b7f82;
    top: 8px;
    left: 14px;
    font-size: 12px;
}
.form-group.focused select.form-control{
    padding-top: 21px;
}
.float-checkradio{
    background-color: #FFF;
    border: 1px solid #DFE3E7;
    border-radius: .267rem;
    padding: 8px 19px;
    transition: 0.3s;
    min-height: 55px;
}
.float-checkradio.focused{
    padding-top: 21px;
}



/*--------select2-css----*/
.select2Part .floating-label{
    opacity: 0;
}
.select2Part.focused .floating-label{
    opacity: 1;
}
.select2multiple .floating-label{
    opacity: 1;
}
.select2Part.focused .select2-container--default .select2-selection--single .select2-selection__rendered{
    padding-top: 13px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered{
    line-height: 40px;
    transition: 240ms;
    padding-right: 40px;
    font-size: 16px;
    font-weight: 400;
    color: #3f3e3d;
    padding-top: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 53px;
    right: 15px;
    transition: 240ms;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: rgb(236 238 241);
    color: #4a494a;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b{
    border: none;
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii00NzMgMjc3IDEyIDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTQ3MyAyNzcgMTIgODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6IzhBOTNBNjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTS00NzEuNiwyNzcuM2w0LjYsNC42bDQuNi00LjZsMS40LDEuNGwtNiw2bC02LTZMLTQ3MS42LDI3Ny4zeiIvPg0KPC9zdmc+DQo=') no-repeat 0 0;
    width: 12px;
    height: 8px;
    background-size: 100% 100%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}
.invalid-select .select2-container--default .select2-selection--single .select2-selection__arrow b{
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iLTQ3MyAyNzcgMTIgOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAtNDczIDI3NyAxMiA4OyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiNmMDY0NjQ7fQo8L3N0eWxlPgogIDxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tNDcxLjYsMjc3LjNsNC42LDQuNmw0LjYtNC42bDEuNCwxLjRsLTYsNmwtNi02TC00NzEuNiwyNzcuM3oiLz4KPC9zdmc+') no-repeat 0 0;
}
.select2-container--default .select2-results__option {
    padding: 12px 12px;
}
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #5A8DEE;
    color: #fff;
}
.select2-container--default .select2-selection--single{
    border-radius: .267rem;
}
.select2-container .select2-selection--single .select2-selection__rendered{
    padding-left: 19px;

}
.select2-results{
    max-height: 220px;
}
.select2-results__option {
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    color: #4F4F4F;
    font-size: 14px;
    font-weight: 400;
}
.select2-container--open .select2-dropdown--above {
    box-shadow: 0px 6px 32px rgba(0, 0, 0, 0.16);
    border-radius: 0px;
    border: none;
    top: 0px;
    overflow: hidden;
}
.select2-container--open .select2-dropdown--below{
    box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.16);
    border-radius: 0px;
    border: none;
    top: 0;
    overflow: hidden;
}
.select2-container--default .select2-search--dropdown .select2-search__field{
    font-size: 14px;
    border: 1px solid #DFE3E7;
    color: #757575;
    padding: 10px 15px;
    background-color: #fff;
    position: relative;
    padding-right: 45px;
}
.select2-container--default .select2-selection--multiple{
    background-color: #f2f2f2;
    border: 0;
    min-height: 50px;
    position: relative;
}
.select2-container--default.select2-container--focus .select2-selection--multiple{
    background-color: #f2f2f2;
    border: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{
    color: #757575;
    line-height: 55px;
    padding-right: 40px;
    display: block;
    height: 100%;
    padding-bottom: 7px;
    padding-top: 17px;
    padding-left: 17px;
    transition: 240ms;
}
.select2-container--default .select2-selection--multiple .select2-selection__arrow {
    height: 48px;
    right: 15px;
}
.select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field{
    line-height: initial;
    padding: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered:before {
    border: none;
    content: '';
    background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9Ii00NzMgMjc3IDEyIDgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgLTQ3MyAyNzcgMTIgODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6IzhBOTNBNjt9DQo8L3N0eWxlPg0KPHBhdGggY2xhc3M9InN0MCIgZD0iTS00NzEuNiwyNzcuM2w0LjYsNC42bDQuNi00LjZsMS40LDEuNGwtNiw2bC02LTZMLTQ3MS42LDI3Ny4zeiIvPg0KPC9zdmc+DQo=') no-repeat 0 0;
    width: 12px;
    height: 8px;
    background-size: 100% 100%;
    transform: translateY(-50%);
    position: absolute;
    right: 18px;
    top: 26px;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none;
    line-height: initial;
    padding: 3px;
    font-size: 14px;
    position: relative;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    cursor: default;
    float: left;
    color: #ffffff;
    margin-right: 5px;
    margin-top: 5px;
    width: initial !important;
    padding: 3px 10px;
    padding-right: 24px !important;
    font-size: 12px !important;
}
.select2-container--default .select2-search--inline .select2-search__field{
    font-size: 14px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    position: absolute;
    font-size: 16px;
    width: 20px;
    height: 20px;
    top: 0;
    text-align: center;
    color: #e45555;
    right: 0px;
}
.floating-group.focused .select2-container--default .select2-selection--multiple .select2-selection__rendered{
    padding-bottom: 7px;
    padding-top: 17px;
    padding-left: 12px;
}
.invalid-select .select2 {
    border: 1px dashed #f06464;
}
.select2 {
    height: 56px;
    border: 1px solid #e7e6e6;
    font-family: GermanedgeSansCn,Arial,Helvetica,sans-serif;
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
}
.select2-container--default .select2-selection--single{
    border: 0;
    border-radius: 0;
    background-color: #f2f2f2;
}
.select2-container .select2-selection--multiple .select2-selection__rendered,
.select2-container .select2-selection--single .select2-selection__rendered{
    padding-left: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered,
.select2-container--default .select2-selection--single .select2-selection__rendered{
    font-size: 14px;
    padding: 0;
    margin: 0;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single{
    height: 54px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--default .select2-selection--single .select2-selection__choice{
    background-color: #3f3e3d;
}
.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected]:hover {
    background-color: #3f3e3d;
}
.select2-container--focus.select2-container--default .select2-selection--single {
    background-color: #f2f2f2;
    border: 0;
}
.select2Part.focused .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-top: 4px;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered:before,
.select2-container--default .select2-selection--single .select2-selection__arrow b{
    transform: rotate(0deg);
}
.select2-container--open .select2-selection--multiple .select2-selection__rendered:before,
.select2-container--open .select2-selection--single .select2-selection__arrow b{
    transform: rotate(180deg);
}
.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #3f3e3d;
    color: #fadd4b;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]:hover{
    background-color: #3f3e3d;
    color: #fff;
}
.is-invalid{
    border: 1px solid red;
}
input[type=text],
input[type=number],
input[type=email],
input[type=password],
select{
    font-weight: 400;
    font-family: GermanedgeSans-Cn-SemiBold,Arial,Helvetica,sans-serif;
    font-size: 14px;
    height: 56px;
    border: 1px solid #e7e6e6;
    padding-left: 16px;
    padding-right: 38px;
    width: 100%;
    border-radius: 0;
    background-color: #f2f2f2;
    color: #3f3e3d;
}
:focus-visible {
    outline: none;
}
select:focus,
select:hover,
input:hover,
input:focus,
textarea:hover,
textarea:focus,
.select2:hover,
.select2:focus {
    border: 1px dashed #b7b6b6;
    color: #0f0e0d;
}
input[type=file]:hover,
input[type=range]:hover,
input[type=file]:focus,
input[type=range]:focus{
    border: none;
}
input:disabled:hover {
    cursor: not-allowed;
    border: 1px solid #e7e6e6;
}
label:not(.form-check-label):not(.custom-file-label) {
    font-weight: lighter;
}
select.is-invalid {
    border: 1px dashed #f06464;
}
